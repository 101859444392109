export const getTransactionParams = ({
                                         cost,
                                         amount,
                                         childTx,
                                     }) => {


    const {
        amount: amountChild,
    } = childTx || {}

    const isDividend = (cost === 0 && amount > 0) || (cost < 0 && amount === 0)
    const isBurn = amount < 0 && cost === 0
    const isSale = amount < 0 && cost < 0 && !isDividend && !isBurn
    const isBuy = amount > 0 && cost > 0 && !isDividend && !isBurn

    const isSwap = !isDividend && amountChild
    const realAmount = isDividend && amountChild ? amountChild : amount

    return {
        isDividend,
        isBurn,
        isSale,
        isBuy,
        isSwap,
        realAmount,
        verb: getTransactionVerb(isDividend, isBurn, isSale),
        verbShort: getTransactionVerb(isDividend, isBurn, isSale, true),
        verbClassName: getClassName(isDividend, isBurn, isSale),
    }
}

const getTransactionVerb = (isDividend, isBurn, isSale, isShort = false) => {
    if (isDividend) {
        return isShort ? 'Div' : 'Dividend'
    }
    if (isBurn) {
        return 'Burn'
    }
    if (isSale) {
        return 'Sale'
    }
    return 'Buy'
}

const getClassName = (isDividend, isBurn, isSale) => {
    if (isDividend) {
        return 'is-golden'
    }
    if (isBurn) {
        return 'is-gas'
    }
    if (isSale) {
        return 'is-negative'
    }
    return 'is-positive'
}