import './StatLine.scss'
import {useSelector} from "react-redux";
import {
    getAssetSelectionAggregate, getComparativeAssetCode, getComparativeAssetName,
    getComparativeAssetRate,
    getCurrentAsset,
    getIsSingleAsset
} from "../../../ducks/assets-duck.js";
import Counter from "../../util/Counter.jsx";
import BriefcaseOutlineIcon from "mdi-react/BriefcaseOutlineIcon.js";
import DatabaseOutlineIcon from "mdi-react/DatabaseOutlineIcon.js";
import SwapHorizontalBoldIcon from "mdi-react/SwapHorizontalBoldIcon.js";
import CalendarOutlineIcon from "mdi-react/CalendarOutlineIcon.js";
import {dayFormat} from "../../../util/time-and-date.js";
import TagOutlineIcon from "mdi-react/TagOutlineIcon.js";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon.js";
import EthereumIcon from "mdi-react/EthereumIcon.js";

export default () => {

    const isSingleAsset = useSelector(getIsSingleAsset)
    const comparativeAssetRate = useSelector(getComparativeAssetRate)
    const comparativeAssetCode = useSelector(getComparativeAssetCode)
    const comparativeAssetName = useSelector(getComparativeAssetName)

    const {
        amount: quantity,
        currentRate,
        currentRateUsd,
    } = useSelector(getCurrentAsset)

    const {
        count,
        value,
        days_age,
        weighted_days_age,
        transaction_count,
        avg_transaction_cost,
    } = useSelector(getAssetSelectionAggregate)
    const weightedDaysAge = weighted_days_age / value
    const txCostWeighted = avg_transaction_cost / count

    const {value: timeInvestedValue, suffix: timeInvestedSuffix} = dayFormat(days_age)
    const {value: timeInvestedWeightedValue, suffix: timeInvestedWeightedSuffix} = dayFormat(weightedDaysAge)

    const isWeightedTimeDisplayed = days_age !== weightedDaysAge && weightedDaysAge !== 0

    return (
        <div
            className='stat-line flex-vertical-center children-slide-down'
        >
            {isSingleAsset &&
                <>
                    <StatPoint
                        icon={<TagOutlineIcon/>}
                        value={currentRate}
                        decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                        label='Price'
                        suffix={'kr'}
                    />
                    {!!currentRateUsd &&
                    <StatPoint
                        className='reduced-gap'
                        icon={<CurrencyUsdIcon/>}
                        value={currentRateUsd}
                        decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                        label='Price USD'
                    />
                    }
                    <StatPoint
                        icon={<BriefcaseOutlineIcon/>}
                        value={count}
                        label='Asset count (avg value)'
                        secondaryValue={value / count}
                    />
                    <StatPoint
                        icon={<DatabaseOutlineIcon/>}
                        decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                        value={quantity}
                        label='Quantity'
                    />
                </>
            }
            <StatPoint
                className='reduced-gap'
                icon={<EthereumIcon/>}
                value={value / comparativeAssetRate}
                decimals={DECIMAL_FORMAT.FLEX_DEC_NO_TRAIL}
                label={`Value in ${comparativeAssetName}`}
                suffix={comparativeAssetCode}
            />
            <StatPoint
                icon={<CalendarOutlineIcon/>}
                value={timeInvestedValue}
                suffix={timeInvestedSuffix}
                secondaryValue={isWeightedTimeDisplayed ? timeInvestedWeightedValue : null}
                secondarySuffix={timeInvestedWeightedSuffix}
                label={isWeightedTimeDisplayed ? 'Time invested (weighted)' : 'Time invested'}
            />
            <StatPoint
                icon={<SwapHorizontalBoldIcon/>}
                value={transaction_count}
                secondaryValue={txCostWeighted}
                secondarySuffixDecimals={DECIMAL_FORMAT.FLEX}
                label='Transactions (avg. amount)'
                className={!isSingleAsset ? 'hide-portrait' : ''}
            />
        </div>
    )
}

const StatPoint = ({
                       icon,
                       value,
                       suffix,
                       decimals = 0,
                       secondaryValue,
                       secondarySuffix,
                       secondarySuffixDecimals,
                       label = '',
                       className=''}) => {
    return (
        <div
            className={`stat-point flex-vertical-center ${className}`}
            data-tooltip-id="my-tooltip"
            data-tooltip-content={label}
        >
            {!!icon &&
            <div className='icon'>
                {icon}
            </div>
            }
            <div className='value'>
                <Counter
                    amount={value}
                    decimals={decimals}
                    suffix={suffix}
                />
            </div>
            {secondaryValue &&
            <div className='secondary-value'>
                <Counter
                    amount={secondaryValue}
                    decimals={secondarySuffixDecimals !== undefined ? secondarySuffixDecimals : decimals}
                    suffix={secondarySuffix}
                />
            </div>
            }
        </div>
    )
}