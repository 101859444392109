import Card from "../../util/Card.jsx";
import InternalLink from "../../util/navigation/InternalLink.jsx";
import {APP_ROUTES} from "../../../App.jsx";
import {DECIMAL_FORMAT, numberFormat} from "../../../util/numbers.js";

export default ({
                    id,
                    asset_name,
                    asset_code,
                    amount,
                }) => {
    return (
        <Card className='connected-transaction'>
            <h4>
                Connection
            </h4>
            <div>
                {asset_name}
                <span className='quantity'>
                    <span className='code'>{amount < 0 ? 'sell' : 'buy'}</span> {numberFormat(Math.abs(amount), DECIMAL_FORMAT.FLEX_DEC)} <span className='code'>{asset_code}</span>
                </span>
            </div>
            <InternalLink to={APP_ROUTES.transactionsPage.replace(`:transactionId`, id)}>
                View transaction
            </InternalLink>
        </Card>
    )
}