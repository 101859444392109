import './AssetSettings.scss'
import AssetUrls from "../asset-stats/AssetUrls.jsx";
import Button from "../../util/Button.jsx";
import TagPlusOutlineIcon from "mdi-react/TagPlusOutlineIcon.js";
import {activateWidePopup} from "../../../ducks/popup-duck.js";
import AddRateDialog from "../history/AddRateDialog.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentAsset} from "../../../ducks/assets-duck.js";
import {getIsOddy} from "../../../ducks/user-duck.js";
import CallSplitIcon from "mdi-react/CallSplitIcon.js";
import AssetSplitDialog from "./AssetSplitDialog.jsx";
import TagOutlineIcon from "mdi-react/TagOutlineIcon.js";
import LinkVariantIcon from "mdi-react/LinkVariantIcon.js";

export default () => {

    const dispatch = useDispatch()
    const isOddy = useSelector(getIsOddy)

    const {
        currentRate,
        id: assetId,
        name: assetName,
        amount: quantity,
    } = useSelector(getCurrentAsset)

    return isOddy && (
        <div className='asset-settings'>
            <section>
                <LinkVariantIcon className='illustration-icon'/>
                <h2>
                    Links
                </h2>
                <AssetUrls/>
            </section>
            <section>
                <TagOutlineIcon className='illustration-icon'/>
                <h2>
                    Rates
                </h2>
                <Button
                    icon={<TagPlusOutlineIcon/>}
                    onClick={() => dispatch(activateWidePopup(
                        <AddRateDialog
                            currentRate={currentRate}
                            assetId={assetId}
                            assetName={assetName}
                        />
                    ))}
                >
                    Add rate
                </Button>
            </section>
            <section>
                <CallSplitIcon className='illustration-icon split-icon'/>
                <h2>
                    Split
                </h2>
                <Button
                    icon={<CallSplitIcon className='split-icon'/>}
                    onClick={() => dispatch(activateWidePopup(
                        <AssetSplitDialog
                            currentQuantity={quantity}
                            assetId={assetId}
                            assetName={assetName}
                        />
                    ))}
                >
                    Perform asset split or merge
                </Button>
            </section>
        </div>
    )
}