import './ChartFocus.scss'
import {CHART_FOCUSES, getChartFocus, setChartFocus} from "../../../ducks/chart-duck.js";
import {useDispatch, useSelector} from "react-redux";

export default () => {
    return (
        <div className='chart-focus'>
            <div className='buttons children-slide-down'>
                {Object.keys(CHART_FOCUSES).map(id => <Focus key={id} id={id}/>)}
            </div>
        </div>
    )
}

const Focus = ({id}) => {
    const dispatch = useDispatch()
    const current = useSelector(getChartFocus)
    return (
        <button
            className={current === id ? `is-selected` : ``}
            onClick={() => dispatch(setChartFocus(id))}
        >
            {CHART_FOCUSES[id].name}
        </button>
    )
}