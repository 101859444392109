import './Breadcrumbs.scss'
import {APP_ROUTES} from "../../../App.jsx";
import {Link, NavLink} from "react-router-dom";
import {useSelector} from "react-redux";
import {
    FILTER_PRESET_NAMES,
    getCurrentAssetCode,
    getCurrentAssetName,
    getFilterPreset
} from "../../../ducks/assets-duck.js";
import {useNavigation} from "../../util/navigation/navigation.js";
import AssetIcon from "../../util/icons/AssetIcon.jsx";
import ChevronRightIcon from "mdi-react/ChevronRightIcon.js";
import CloseIcon from "mdi-react/CloseIcon.js";
import {getSubPathCache} from "../../../ducks/user-duck.js";

export default () => {
    const subPathCache = useSelector(getSubPathCache)
    const filterPreset = useSelector(getFilterPreset)
    const assetName = useSelector(getCurrentAssetName)
    const assetCode = useSelector(getCurrentAssetCode)
    const {getRoute} = useNavigation()
    const route = subPathCache ? APP_ROUTES.history.replace('history', subPathCache) : APP_ROUTES.history

    const filterPresetName = FILTER_PRESET_NAMES[filterPreset]
    const filterPresetNameParts = filterPresetName?.split(' ') || []

    return (
        <div className={'breadcrumbs'}>
            <div className='links'>
                <NavLink
                    to={getRoute(APP_ROUTES.filter, {filterId: filterPreset,})}
                    activeClassName="is-active"
                    exact={true}
                >
                    <AssetIcon filterPreset={filterPreset}/>
                    <div className='filter-preset'>
                        {filterPresetNameParts.map(part => <span key={part}>{part}</span>)}
                        {filterPreset === 'dawg' && 'SushiDog\'s SushiLog'}
                    </div>
                </NavLink>
                {!!assetName &&
                <>
                    <ChevronRightIcon className='breadcrumb-separator'/>
                    <div className='asset-name'>
                        <div className='name'>{assetName}</div>
                        <div className='code'>{assetCode}</div>
                    </div>
                    <Link to={getRoute(route, {filterId: filterPreset,})} className='reset-asset-selection'>
                        <CloseIcon />
                    </Link>
                </>
                }
            </div>
        </div>
    )
}