import {createSelector, createSlice} from "@reduxjs/toolkit";
import {isToday, isYesterday} from "../util/time-and-date.js";
import {getStorage, setStorage} from "../api/_token.js";
import orderBy from "lodash/orderBy.js";
import {ASSET_IDS} from "../util/asset-ids.js";

export const NAME = 'assets';

export const FILTER_KEY_ALL = 'all'
export const FILTER_KEY_CRYPTO = 'crypto'
export const FILTER_KEY_STOCKS = 'stocks'
export const FILTER_KEY_FUNDS = 'funds'
export const FILTER_KEY_REAL_ESTATE = 'real-estate'

export const FILTER_PRESET_NAMES = {
    [FILTER_KEY_ALL]: 'All assets',
    [FILTER_KEY_CRYPTO]: 'Crypto',
    [FILTER_KEY_STOCKS]: 'Stocks',
    [FILTER_KEY_FUNDS]: 'Funds',
    [FILTER_KEY_REAL_ESTATE]: 'Real estate',
}

export const assetTypeToGroup = assetType => {
    if (assetType.includes('FUND')) {
        return FILTER_KEY_FUNDS
    }
    if (assetType.includes('CRYPTO')) {
        return FILTER_KEY_CRYPTO
    }
    if (assetType.includes('STOCK') && assetType !== 'STOCK-UNLISTED') {
        return FILTER_KEY_STOCKS
    }
    return FILTER_KEY_REAL_ESTATE
}


const initialState = {
    assets: [],
    assetIdsFiltered: [],
    isDustFiltered: getStorage('is-dust-filtered', true),
    dustThreshold: getStorage('dust-threshold', 5000),
    comparativeAssetId: getStorage('comparative-asset-id', ASSET_IDS.ETH),
    sortProp: getStorage('asset-list-sort-prop', 'changePct'),
    sortIsAsc: getStorage('asset-list-sort-is-asc', false),
    filter: FILTER_KEY_ALL,
    filterPreset: FILTER_KEY_ALL,
    percentForBarometerGraphic: getStorage('barometer-percent', true),
    isAssetListCompositionBarVisible: getStorage('is-asset-list-composition-bar-visible', false),
    isLiveChangesVisible: getStorage('is-live-changes-visible', true),
    isAllAssetListGrouped: getStorage('is-all-asset-list-grouped', false),
    dateFilter: null,
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setAssets: (state, {payload: assets}) => {
            state.assets = assets.map(asset => {
                const prevValue = asset.value / (1 + asset.changePct)
                const change = asset.value - prevValue
                const isRateFromToday = isToday(asset.currentRateDate) && (asset.asset_type !== 'REAL_ESTATE' || isYesterday(asset.prevRateDate))
                return {
                    ...asset,
                    isRateFromToday,
                    prevValue,
                    change,
                }
            })
        },
        setIsDustFiltered: (state, {payload}) => {
            setStorage('is-dust-filtered', payload)
            state.isDustFiltered = payload
        },
        setDustThreshold: (state, {payload}) => {
            setStorage('dust-threshold', payload)
            state.dustThreshold = payload
        },
        setFilter: (state, {payload}) => {
            state.filter = payload
        },
        setFilterPreset: (state, {payload}) => {
            state.filterPreset = payload
        },
        setSortProp: (state, {payload}) => {
            setStorage('asset-list-sort-prop', payload)
            state.sortProp = payload
        },
        setSortIsAsc: (state, {payload}) => {
            setStorage('asset-list-sort-is-asc', payload)
            state.sortIsAsc = payload
        },
        setComparativeAssetId: (state, {payload}) => {
            setStorage('comparative-asset-id', payload)
            state.comparativeAssetId = payload
        },
        setPercentForBarometerGraphic: (state, {payload}) => {
            setStorage('barometer-percent', payload)
            state.percentForBarometerGraphic = payload
        },
        setIsAssetListCompositionBarVisible: (state, {payload}) => {
            setStorage('is-asset-list-composition-bar-visible', payload)
            state.isAssetListCompositionBarVisible = payload
        },
        setIsLiveChangesVisible: (state, {payload}) => {
            setStorage('is-live-changes-visible', payload)
            state.isLiveChangesVisible = payload
        },
        setIsAllAssetListGrouped: (state, {payload}) => {
            setStorage('is-all-asset-list-grouped', payload)
            state.isAllAssetListGrouped = payload
        },
        setDateFilter: (state, {payload}) => {
            state.dateFilter = payload
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setAssets,
    setFilter,
    setFilterPreset,
    setComparativeAssetId,
    setIsDustFiltered,
    setDustThreshold,
    setSortProp,
    setSortIsAsc,
    setIsAssetListCompositionBarVisible,
    setIsLiveChangesVisible,
    setIsAllAssetListGrouped,
    setDateFilter,
} = actions

const getState = state => state[NAME]

export const getIsDustFiltered = state => getState(state).isDustFiltered
export const getDustThreshold = state => getState(state).dustThreshold

export const getAllAssets = state => getState(state).assets
export const getFilter = state => getState(state).filter
export const getFilterPreset = state => getState(state).filterPreset
export const getComparativeAssetId = state => getState(state).comparativeAssetId

export const getDateFilter = state => getState(state).dateFilter

export const getIsBreadcrumbsVisible = state => !['dawg'].includes(getFilterPreset(state))

export const getPercentForBarometerGraphic = state => getState(state).percentForBarometerGraphic
export const getIsAssetListCompositionBarVisible = state => getState(state).isAssetListCompositionBarVisible
export const getIsLiveChangesVisible = state => getState(state).isLiveChangesVisible
export const getIsAllAssetListGrouped = state => getState(state).isAllAssetListGrouped

export const getSortProp = state => getState(state).sortProp
export const getSortIsAsc = state => getState(state).sortIsAsc

export const applyFilter = (filter, assets) => {
    if (Number.isInteger(filter)) {
        return assets.filter(asset => asset.id === filter)
    }
    if (filter !== FILTER_KEY_ALL) {
        return assets.filter(asset => assetTypeToGroup(asset.asset_type) === filter)
    }
    return assets
}

export const markWithRecentRate = (assets = []) => {

    if (assets.length === 0) return []

    const assetsOrdered = orderBy(assets.filter(asset => asset.currentRateDate), ['currentRateDate'], ['desc'])
    const mostRecentDate = assetsOrdered[0].currentRateDate

    return assets.map(asset => ({
        ...asset,
        isMostRecentUpdate: asset.currentRateDate === mostRecentDate
    }))

}

export const getAssetsFilterPreset = createSelector([getAllAssets, getFilter], (allAssets, filter) => {
    return markWithRecentRate(applyFilter(filter, allAssets))
})

export const getUsdRate = createSelector([getAllAssets], (allAssets) => {
    const asset = allAssets.find(asset => asset.id === 16)
    return asset.currentRate / asset.currentRateUsd
})

export const getAssetsFiltered = createSelector([getAssetsFilterPreset, getIsDustFiltered, getDustThreshold], (assets, isDustFiltered, dustThreshold) => {
    if (assets.length === 1) {
        return assets
    }
    if (isDustFiltered) {
        return assets.filter(asset => Math.abs(asset.value) > dustThreshold)
    }
    return assets
})
export const getAllPresetFilterAssets = createSelector([getAllAssets, getFilterPreset], (allAssets, filterPreset) => applyFilter(filterPreset, allAssets))

export const getAssets = state => getAssetsFiltered(state)
export const getIsSingleAsset = state => getAssets(state)?.length === 1
export const getAssetIds = createSelector([getAssets], assets => assets.map(asset => asset.id))
export const getAllAssetIds = createSelector([getAllAssets], assets => assets.map(asset => asset.id))

export const getAssetSelectionAggregate = createSelector([getAssets], assets => assets.reduce((acc, cur) => ({
    value: acc.value + cur.value,
    cost: acc.cost + cur.cost,
    gain: acc.gain + cur.gain,
    transaction_count: acc.transaction_count + cur.transaction_count,
    avg_transaction_cost: acc.avg_transaction_cost + cur.avg_transaction_cost,
    days_age: Math.max(acc.days_age, cur.days_age),
    weighted_days_age: acc.weighted_days_age + (cur.weighted_days_age * cur.value),
    count: acc.count + 1,
    quantity: acc.amount + cur.amount,
}), {
    count: 0,
    value: 0,
    cost: 0,
    gain: 0,
    transaction_count: 0,
    avg_transaction_cost: 0,
    days_age: 0,
    weighted_days_age: 0,
    quantity: 0,
}))

export const getAssetsTotalValue = createSelector([getAssets], assets => assets.reduce((acc, cur) => acc + cur.value, 0))
export const getAssetsTotalGain = createSelector([getAssets], assets => assets.reduce((acc, cur) => acc + (cur.value - cur.cost), 0))
export const getCurrentAsset = createSelector([getAssets], assets => assets.length !== 1 ? {} : assets[0])
export const getCurrentAssetName = createSelector([getCurrentAsset], asset => asset.name)
export const getCurrentAssetCode = createSelector([getCurrentAsset], asset => asset.code)
export const getCurrentAssetId = createSelector([getCurrentAsset], asset => asset.id)

export const getCurrentAssetRate = createSelector([getCurrentAsset], asset => asset.currentRate || 0)
export const getCurrentAssetPrevRate = createSelector([getCurrentAsset], asset => asset.prevRate || 0)
export const getCurrentAssetRateChange = createSelector([getCurrentAssetRate, getCurrentAssetPrevRate], (rate, prevRate) => rate - prevRate)
export const getCurrentAssetRateDate = createSelector([getCurrentAsset], asset => asset.currentRateDate || '')
export const getCurrentAssetPrevRateDate = createSelector([getCurrentAsset], asset => asset.prevRateDate || '')
export const getCurrentAssetRateChangePct = createSelector([getCurrentAsset], asset => asset.changePct || 0)


export const getSpecificAsset = (state, assetId) => getAllAssets(state).find(asset => asset.id === assetId) || {}
export const getAssetRate = (state, assetId) => getSpecificAsset(state, assetId).currentRate
export const getRethRate = state => getAssetRate(state, ASSET_IDS.RETH) || 10_000

export const getAssetSol = state => getSpecificAsset(state, ASSET_IDS.SOL)
export const getAssetBitcoin = state => getSpecificAsset(state, ASSET_IDS.BTC)

export const getComparativeAsset = state => getSpecificAsset(state, getComparativeAssetId(state) || ASSET_IDS.ETH) || {}
export const getComparativeAssetRate = state => getComparativeAsset(state).currentRate || 0.00000001
export const getComparativeAssetCode = state => getComparativeAsset(state).code || 'ETH'
export const getComparativeAssetName = state => getComparativeAsset(state).name || 'Ethereum'

export default reducer