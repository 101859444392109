import {useQuery} from "@tanstack/react-query";
import {MIN} from "../util/time-and-date.js";
import {get} from "./_fetch.js";
import {API_ROUTE_ANNOTATIONS} from "../../../back-end/constants/API-ROUTES.js";

export const useAnnotations = () => {

    const {
        data = [],
        refetch,
    } = useQuery({
        queryKey: ['annotations-api'],
        queryFn: () => get(API_ROUTE_ANNOTATIONS),
        refetchInterval: 5 * MIN,
    })

    return {
        annotations: data,
        refetch,
    }

}