import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {getFilter} from "../../../ducks/assets-duck.js";
import {useLocation} from "react-router";
import {useMemo} from "react";
import {APP_ROUTES} from "../../../App.jsx";

export const scrollTo = y => window.scrollTo({top: y, behavior: 'smooth'})
export const scrollToTop = () => scrollTo(0)

export const scrollToDashBoard = () => {
    const dashboard = document.getElementById('dashboard')
    if(!dashboard){
        scrollTo(window.offsetTop)
    } else if(dashboard.offsetTop < window.scrollY){
        scrollTo(dashboard.offsetTop)
    }
}

export const injectParams = (route, params = {}) =>
    Object.keys(params).reduce((acc, cur) => {
        return acc.replace(`:${cur}`, params[cur])
    }, route)

export const useNavigation = (scrollFn = scrollToDashBoard) => {

    const history = useHistory()
    const location = useLocation()
    const filter = useSelector(getFilter)

    const getRoute = (path, params = {}) => {
        return injectParams(path, {
            filterId: filter,
            ...params,
        })
    }

    const navigateTo = (route, params = {}) => {
        history.push(getRoute(route, params))
        scrollFn()
    }

    const navigateToDashBoard = (filterId = filter) => {
        navigateTo(APP_ROUTES.filter, { filterId })
    }

    return {
        navigateTo,
        getRoute,
        navigateToDashBoard,
        path: location.pathname,
    }

}

export const useSearchParams = () => {

    const { search } = useLocation()

    return useMemo(
        () => Object.fromEntries(new URLSearchParams(search).entries()),
        [search],
    )
}