import {useSelector} from "react-redux";
import {
    FILTER_KEY_ALL,
    getAssets,
    getDateFilter,
    getFilterPreset,
    getIsAllAssetListGrouped,
    getSortIsAsc,
    getSortProp
} from "../../../ducks/assets-duck.js";
import {groupAssetsByType} from "./_helpers.js";
import orderBy from "lodash/orderBy.js";
import {getDateFilterAssets} from "../../../ducks/history-duck.js";

export const useAssets = () => {

    const assets = useSelector(getAssets)
    const dateFilter = useSelector(getDateFilter)
    const dateFilterAssets = useSelector(getDateFilterAssets)
    const filterPreset = useSelector(getFilterPreset)
    const currentSortProp = useSelector(getSortProp)
    const currentSortIsAsc = useSelector(getSortIsAsc)
    const isAllAssetListGrouped = useSelector(getIsAllAssetListGrouped)

    const assetsDateFiltered = !dateFilter || !dateFilterAssets ? assets : assets.map(asset => {
        const dateAsset = dateFilterAssets[asset.id]
        return dateAsset ? {
            ...asset,
            ...dateAsset,
            changePct: dateAsset.gainChangePercent,
            currentRateUsd: dateAsset.usdRate,
            currentRate: dateAsset.rate,
            currentRateDate: dateFilter,
            isMostRecentUpdate: !!dateAsset.gainChangePercent,
            isHistoricDate: true,
        } : asset
    })

    const isAllAssetList = filterPreset === FILTER_KEY_ALL

    const assetsGrouped = isAllAssetList && isAllAssetListGrouped ? groupAssetsByType(assetsDateFiltered) : assetsDateFiltered

    const assetsOrdered = orderBy(
        assetsGrouped,
        ['isMostRecentUpdate', currentSortProp, 'value'],
        ['desc', currentSortIsAsc ? 'asc' : 'desc', 'desc']
    )

    return {
        assetsOrdered,
        isAllAssetList,
        isAllAssetListGrouped,
        dateFilter: !!dateFilterAssets && dateFilter,
    }

}