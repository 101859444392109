import {useQuery} from "@tanstack/react-query";
import {getAssetChartsApi} from "../../../api/statusApi.js";
import {MIN} from "../../../util/time-and-date.js";
import {useChillEffect} from "../../../util/mounting-hooks.js";


export let ASSET_CHART_DATA = {}

export const useAssetCharts = () => {

    const {data = []} = useQuery({
        queryKey: ['charts-api'],
        queryFn: getAssetChartsApi,
        refetchInterval: MIN,
    })

    useChillEffect(() => {
        for (const chart of data) {
            const {asset_id, image} = chart
            ASSET_CHART_DATA[asset_id] = image
        }
    }, [data])

    return {}

}