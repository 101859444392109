import {createSelector, createSlice} from "@reduxjs/toolkit";
import {getAssetIds} from "./assets-duck.js";
import {getStorage, setStorage} from "../api/_token.js";
import {ASSET_IDS} from "../util/asset-ids.js";

export const NAME = 'transactions';

const initialState = {
    transactions: [],
    transactionsGrouped: [],
    portfolioAggregateDateMap: {},
    latestUpdate: null,
    filterBurn: getStorage('tx-filter-filterBurn', 0),
    filterCompany: getStorage('tx-filter-filterCompany', 0),
    filterComment: getStorage('tx-filter-filterComment', 0),
}

const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        setTransactions: (state, {payload}) => {
            state.transactions = payload
        },
        setTransactionsGrouped: (state, {payload}) => {
            state.transactionsGrouped = payload
        },
        setPortfolioAggregateDateMap: (state, {payload: portfolioAggregateDateMap}) => {
            state.portfolioAggregateDateMap = portfolioAggregateDateMap
        },
        setLatestUpdate: (state, {payload}) => {
            state.latestUpdate = payload
        },
        toggleTransactionFilter: (state, {payload}) => {
            state[payload]++
            if (state[payload] > 1) {
                state[payload] = -1
            }
            setStorage(`tx-filter-${payload}`, state[payload])
        },
        reset: () => ({
            ...initialState
        }),
    }
})

export const {
    setTransactions,
    setTransactionsGrouped,
    setPortfolioAggregateDateMap,
    setLatestUpdate,
    toggleTransactionFilter,
} = actions

const getState = state => state[NAME]

export const getTransactionsRaw = state => getState(state).transactions
export const getTransactionsGrouped = state => getState(state).transactionsGrouped
export const getPortfolioAggregateDateMap = state => getState(state).portfolioAggregateDateMap
export const getLatestUpdate = state => getState(state).latestUpdate
export const getSpecificTransaction = (state, transactionId) => {
    const {parent_transaction_id, ...transaction} = findTransactionInList(getTransactionsGrouped(state),transactionId) || findTransactionInList(getTransactionsRaw(state),transactionId)
    if(parent_transaction_id){
        transaction.parentTx = findTransactionInList(getTransactionsRaw(state),parent_transaction_id)
    }
    return transaction
}

const findTransactionInList = (transactionList, transactionId) =>
    transactionList.find(tx => tx.id === parseInt(transactionId))


export const getTransactionFilter = (state, prop) => getState(state)[prop] || 0
export const getFilterBurn = state => getState(state).filterBurn
export const getFilterCompany = state => getState(state).filterCompany
export const getFilterComment = state => getState(state).filterComment

export const getTransactionsFiltered = createSelector(
    [getTransactionsGrouped, getAssetIds, getFilterBurn, getFilterCompany, getFilterComment],
    (transactions, assetIds, filterBurn, filterCompany, filterComment) => {
        const transactionsAssetFiltered = transactions.filter(tx => assetIds.includes(tx.fund_id) || assetIds.includes(tx.childTx?.fund_id))
        if (filterBurn === 0 && filterCompany === 0 && filterComment === 0) {
            return transactionsAssetFiltered
        }
        const burnFiltered = applyBurnFilter(transactionsAssetFiltered, filterBurn)
        const companyFiltered = applyCompanyFilter(burnFiltered, filterCompany)
        return applyCommentFilter(companyFiltered, filterComment)
    }
)

export const getTaxReportTransactions = createSelector([getTransactionsRaw, getAssetIds], (transactions, assetIds) =>
    transactions.filter(tx =>
        assetIds.includes(tx.fund_id)
        && !tx.is_company
        && tx.fund_id !== ASSET_IDS.DAI // Ignore DAI
    )
)

const applyBurnFilter = (transactions, value) => {
    switch (value) {
        case -1:
            return transactions.filter(tx => !tx.is_gas_cost)
        case 1:
            return transactions.filter(tx => !!tx.is_gas_cost)
        default:
            return transactions
    }
}
const applyCompanyFilter = (transactions, value) => {
    switch (value) {
        case -1:
            return transactions.filter(tx => !tx.is_company)
        case 1:
            return transactions.filter(tx => !!tx.is_company)
        default:
            return transactions
    }
}
const applyCommentFilter = (transactions, value) => {
    switch (value) {
        case -1:
            return transactions.filter(tx => !tx.comment)
        case 1:
            return transactions.filter(tx => !!tx.comment)
        default:
            return transactions
    }
}

export default reducer