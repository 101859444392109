import {useChillEffect} from "../../../util/mounting-hooks.js";
import {useDispatch} from "react-redux";
import {FILTER_KEY_ALL, setFilter, setFilterPreset} from "../../../ducks/assets-duck.js";

export default () => {

    const dispatch = useDispatch()

    useChillEffect(() => {
        dispatch(setFilter(FILTER_KEY_ALL))
        dispatch(setFilterPreset(FILTER_KEY_ALL))
    }, [])

    return null
}