import './AppLoader.scss'
import LoaderCircle from "../util/loaders/LoaderCircle.jsx";
import {useDispatch, useSelector} from "react-redux";
import {getSetupError} from "../../ducks/setup-duck.js";
import 'react-json-pretty/themes/monikai.css';
import JSONPretty from "react-json-pretty";
import Button from "../util/Button.jsx";
import RefreshIcon from "mdi-react/RefreshIcon.js";
import {reset as resetSetup} from "../../ducks/setup-duck.js";
import {logout} from "../../ducks/user-duck.js";

export default () => {

    const dispatch = useDispatch()

    const setupError = useSelector(getSetupError)

    const resetApp = () => {
        dispatch(logout())
        dispatch(resetSetup())
    }

    return (
        <div className='app-loader'>
            {setupError ?
                <>
                    <JSONPretty data={setupError} className='error' />
                    <Button
                        icon={<RefreshIcon />}
                        onClick={resetApp}
                    >
                        Reset app
                    </Button>
                </>
                :
                <LoaderCircle />
            }
        </div>
    )
}