import {useChillEffect} from "../../../util/mounting-hooks.js";
import {useDispatch, useSelector} from "react-redux";
import {FILTER_KEY_ALL, getAssetIds, setFilter, setFilterPreset} from "../../../ducks/assets-duck.js";
import {useLocation, useParams} from "react-router-dom";
import {APP_ROUTES} from "../../../App.jsx";
import {setSubPathCache} from "../../../ducks/user-duck.js";

const SUB_PATH_CACHE_EXCEPTIONS = [
    'apply-hover-date-filter',
]

export default () => {

    const dispatch = useDispatch()
    const assetIds = useSelector(getAssetIds)

    const {pathname} = useLocation()
    const [,,,subPath] = pathname.split("/")

    const {filterId} = useParams(APP_ROUTES.filter)

    useChillEffect(() => {
        // console.log("new filter", filterId)
        if (!isNaN(filterId)) {
            dispatch(setFilter(parseInt(filterId)))
        } else if (!!filterId) {
            dispatch(setFilter(filterId))
            dispatch(setFilterPreset(filterId))
        } else {
            dispatch(setFilter(FILTER_KEY_ALL))
            dispatch(setFilterPreset(FILTER_KEY_ALL))
        }

    }, [filterId])

    useChillEffect(()=>{
        // console.log("new assetIds", assetIds)
    },[assetIds])

    useChillEffect(()=>{
        if(!!subPath && !SUB_PATH_CACHE_EXCEPTIONS.includes(subPath)){
            dispatch(setSubPathCache(subPath))
        }
    },[subPath])

    return null
}