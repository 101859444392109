import './Compare.scss'
import {useState} from "react";
import AssetSelector from "../../util/asset-selector/AssetSelector.jsx";
import {useSelector} from "react-redux";
import {getFilterPreset} from "../../../ducks/assets-duck.js";
import ArrowLeftRightIcon from "mdi-react/ArrowLeftRightIcon.js";
import ArrowUpDownIcon from "mdi-react/ArrowUpDownIcon.js";
import {getHistoryArray} from "../../../ducks/history-duck.js";
import Counter from "../../util/Counter.jsx";

export default () => {

    const filterPreset = useSelector(getFilterPreset)

    const [firstAssetId, setFirstAssetId] = useState(16)
    const [secondAssetId, setSecondAssetId] = useState(128)

    const isSelectionComplete = !!firstAssetId && !!secondAssetId

    return (
        <div className='asset-compare'>
            <div className={`asset-selectors ${isSelectionComplete ? 'is-selection-complete' : ''}`}>
                <AssetSelector
                    headerSelect='Select asset swapping from'
                    headerSelected='Asset 1'
                    selectedAssetId={firstAssetId}
                    selectAsset={setFirstAssetId}
                    filterPreset={filterPreset}
                />
                <div className='center-arrow'>
                    {isSelectionComplete ? <ArrowLeftRightIcon className='std-icon'/> :
                        <ArrowUpDownIcon className='std-icon'/>}
                </div>
                <AssetSelector
                    headerSelect='Select asset swapping from'
                    headerSelected='Asset 2'
                    selectedAssetId={secondAssetId}
                    selectAsset={setSecondAssetId}
                    filterPreset={filterPreset}
                />
            </div>
            {isSelectionComplete && <Result firstAssetId={firstAssetId} secondAssetId={secondAssetId}/>}
        </div>
    )
}

const getRates = (history, offset, id1, id2) => {
    if (offset > history.length - 1) {
        return [0, 0]
    }
    const [, date] = history[history.length - 1 - offset]
    return [date[id1]?.rate || 0, date[id2]?.rate]
}

const Result = ({firstAssetId, secondAssetId}) => {
    return (
        <div className='result'>
            <Period
                label='week'
                span={7}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='month'
                span={31}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='3 months'
                span={92}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='6 months'
                span={183}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='year'
                span={365}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='2 years'
                span={365 * 2}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
            <Period
                label='3 years'
                span={365 * 3}
                firstAssetId={firstAssetId}
                secondAssetId={secondAssetId}
            />
        </div>
    )
}

const Period = ({span = 0, firstAssetId, secondAssetId, label}) => {

    const history = useSelector(getHistoryArray)

    const [rateNow1, rateNow2] = getRates(history, 0, firstAssetId, secondAssetId)
    const [rateThen1, rateThen2] = getRates(history, span, firstAssetId, secondAssetId)

    const diff1 = rateNow1 - rateThen1
    const diff2 = rateNow2 - rateThen2

    const diffPct1 = diff1 / (rateThen1 || 1) * 100
    const diffPct2 = diff2 / (rateThen2 || 1) * 100

    const pctDiff = diffPct1 - diffPct2


    return !!rateThen1 && !!rateThen2 && (
        <div className='period'>
            <div className='asset a1'>
                <Counter amount={rateThen1}/> -> <Counter amount={rateNow1}/> <Counter amount={diffPct1} suffix='%'
                                                                                       decimals={4}/>
            </div>
            <div className='label'>
                {pctDiff > 0 && <Counter amount={pctDiff} decimals={2} prefix='+' suffix='% '/>}
                {label}
                {pctDiff < 0 && <Counter amount={pctDiff * -1} decimals={2} prefix=' +' suffix='%'/>}
            </div>
            <div className='asset a2'>
                <Counter amount={rateThen2}/> -> <Counter amount={rateNow2}/> <Counter amount={diffPct2} suffix='%'
                                                                                       decimals={4}/>
            </div>
        </div>
    )
}