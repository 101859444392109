import {DECIMAL_FORMAT, numberFormat} from "../../../util/numbers.js";
import Counter from "../../util/Counter.jsx";
import AthBadge from "../../util/AthBadge.jsx";
import {getIsAssetDollarBased, getIsAth} from "./_helpers.js";

export default props => {

    const {
        daysSinceRate,
        isHistoricDate,
        isGroup,
        currentRateUsd,
        currentRate,
        ratio,
    } = props

    const isUsdAsset = getIsAssetDollarBased(props)
    const isAth = getIsAth(props)

    return (
        <div className='rate'>
            <div className='flex-vertical-center'>
                {daysSinceRate > 0 && !isHistoricDate &&
                <div className='rate-freshness'>
                    {numberFormat(daysSinceRate, 0)}
                </div>
                }
                {!isGroup &&
                <Counter
                    amount={isUsdAsset ? currentRateUsd : currentRate}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    prefix={isUsdAsset ? '$ ' : 'kr '}
                    className={isAth ? 'is-golden' : ''}
                />
                }
                {isGroup &&
                <Counter
                    amount={ratio * 100}
                    decimals={DECIMAL_FORMAT.FLEX_DEC}
                    suffix={'%'}
                />
                }
                {isAth && <AthBadge/>}
            </div>
        </div>
    )
}