import {Provider, useSelector} from 'react-redux';

import Login from "./modules/auth/Login.jsx"
import {Tooltip} from 'react-tooltip'
import store from './ducks/_root-reducer.js'
import AssetList from "./modules/assets/asset-list/AssetList.jsx";
import {QueryClient, QueryClientProvider,} from '@tanstack/react-query'
import Setup, {RateRefresh} from "./modules/setup/Setup.jsx";
import Chart from "./modules/assets/chart/Chart.jsx";
import {HashRouter as Router, Route, Switch} from 'react-router-dom';
import ResetRoute from "./modules/assets/routing/ResetRoute.jsx";
import FilterRoute from "./modules/assets/routing/FilterRoute.jsx";
import Nav from "./modules/nav/Nav.jsx";
import IconGradients from "./modules/util/scss/IconGradients.jsx";
import AssetStats from "./modules/assets/asset-stats/AssetStats.jsx";
import AssetHistory from "./modules/assets/history/AssetHistory.jsx";
import AssetTransactions from "./modules/assets/transactions/AssetTransactions.jsx";
import {getIsSetupFinished} from "./ducks/setup-duck.js";
import AppLoader from "./modules/layout/AppLoader.jsx";
import Dashboard from "./modules/assets/dashboard/Dashboard.jsx";
import {getIsLoggedIn} from "./ducks/user-duck.js";
import TransactionWizard from "./modules/assets/transactions/add/TransactionWizard.jsx";
import TransactionPage from "./modules/assets/transactions/TransactionPage.jsx";
import Popup from "./modules/util/popup/Popup.jsx";
import Plans from "./modules/plans/Plans";
import AddTransactionsBulk from "./modules/assets/transactions/bulk/AddTransactionsBulk.jsx";
import Analysis from "./modules/assets/analysis/Analysis.jsx";
import Dawg from "./modules/dawg/Dawg.jsx";
import Compare from "./modules/assets/asset-list/Compare.jsx";
import TaxReport from "./modules/tax-report/TaxReport.jsx";
import CryptoBalances from "./modules/crypto/CryptoBalances.jsx";
import LiveChanges from "./modules/live-changes/LiveChanges.jsx";
import ApplyHoverDateFilter from "./modules/assets/chart/ApplyHoverDateFilter.jsx";
import DogRedirect from "./modules/assets/routing/DogRedirect";
import AssetSettings from "./modules/assets/asset-settings/AssetSettings.jsx";

const queryClient = new QueryClient()

export const getStore = () => store

export const APP_ROUTES = {
    reset: '/',
    assets: '/assets',
    filter: '/assets/:filterId',
    filterWildcard: '/assets/:filterId*',
    history: '/assets/:filterId/history',
    transactions: '/assets/:filterId/transactions',
    transactionsAddBulk: '/assets/:filterId/transactions/add/bulk',
    transactionsAdd: '/assets/:filterId/transactions/add',
    transactionsPage: '/assets/:filterId/transactions/:transactionId',
    stats: '/assets/:filterId/stats',
    settings: '/assets/:filterId/settings',
    analysis: '/assets/:filterId/analysis',
    compare: '/assets/:filterId/compare',
    taxReport: '/assets/:filterId/tax-report',
    cryptoBalances: '/assets/:filterId/crypto/balances',
    applyHoverDateFilter: '/assets/:filterId/apply-hover-date-filter',
    dawg: '/dawg',
}

const LoginWrapper = () => {

    const isLoggedIn = useSelector(getIsLoggedIn)

    if (!isLoggedIn) {
        return <Login/>
    }

    return <Content/>
}

const Content = () => {
    const isSetupFinished = useSelector(getIsSetupFinished)
    return (
        <Router hashType='slash'>
            <Setup/>
            {isSetupFinished ?
                <>
                    <RateRefresh/>
                    <DogRedirect />
                    <Switch>
                        <Route path={APP_ROUTES.dawg} component={Dawg}/>
                        <Route path={APP_ROUTES.taxReport} component={TaxReport}/>
                        <Route path={APP_ROUTES.cryptoBalances} component={CryptoBalances}/>
                        <Route path={'*'} component={AssetsRoute}/>
                    </Switch>
                    <Nav/>
                    <Plans/>
                </>
                :
                <AppLoader/>
            }
            <LiveChanges />
            <Popup/>
            <Tooltip
                id="my-tooltip"
                variant='light'
            />
            <IconGradients/>
        </Router>
    )
}

const AssetsRoute = () => {
    return (
        <>
            <Chart />
            <Dashboard />
            <Switch>
                <Route path={APP_ROUTES.history} component={AssetHistory}/>
                <Route path={APP_ROUTES.transactionsAddBulk} component={AddTransactionsBulk}/>
                <Route path={APP_ROUTES.transactionsAdd} component={TransactionWizard}/>
                <Route path={APP_ROUTES.transactionsPage} component={TransactionPage}/>
                <Route path={APP_ROUTES.transactions} component={AssetTransactions}/>
                <Route path={APP_ROUTES.stats} component={AssetStats}/>
                <Route path={APP_ROUTES.settings} component={AssetSettings}/>
                <Route path={APP_ROUTES.analysis} component={Analysis}/>
                <Route path={APP_ROUTES.compare} component={Compare}/>
                <Route path={APP_ROUTES.applyHoverDateFilter} component={ApplyHoverDateFilter}/>
                <Route path={'*'} component={AssetList}/>
            </Switch>
            <Switch>
                <Route path={APP_ROUTES.filter} component={FilterRoute}/>
                <Route path={APP_ROUTES.filterWildcard} component={FilterRoute}/>
                <Route path={APP_ROUTES.reset} component={ResetRoute}/>
            </Switch>
        </>
    )
}

function App() {
    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <LoginWrapper/>
            </QueryClientProvider>
        </Provider>
    )
}

export default App
