import './TransactionPage.scss'
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSpecificTransaction} from "../../../ducks/transactions-duck.js";
import Counter from "../../util/Counter.jsx";
import {getSpecificAsset} from "../../../ducks/assets-duck.js";
import DirectionIcon from "../../util/icons/DirectionIcon.jsx";
import GasIcon from "mdi-react/GasIcon.js";
import {getDirectionColorClassName} from "../../../util/misc.js";
import {DECIMAL_FORMAT} from "../../../util/numbers.js";
import {useState} from "react";
import Button from "../../util/Button.jsx";
import {deleteTransactionApi, patchTransactionApi} from "../../../api/statusApi.js";
import {useNavigation} from "../../util/navigation/navigation.js";
import {APP_ROUTES} from "../../../App.jsx";
import {setup} from "../../setup/_setup.js";
import Toggle from "../../util/Toggle.jsx";
import InternalLink from "../../util/navigation/InternalLink.jsx";
import ChevronRightIcon from "mdi-react/ChevronRightIcon.js";
import ConnectedTransaction from "./ConnectedTransaction.jsx";
import {useChillEffect} from "../../../util/mounting-hooks.js";
import {getTransactionParams} from "./_helpers.js";

export default () => {

    const dispatch = useDispatch()
    const {navigateTo, getRoute} = useNavigation()
    const {transactionId} = useParams()
    const transaction = useSelector(state => getSpecificTransaction(state, transactionId))

    if (!transaction) {
        navigateTo(APP_ROUTES.transactions)
        return
    }

    const asset = useSelector(state => getSpecificAsset(state, transaction.fund_id))

    const {
        id,
        date,
        asset_name,
        amount,
        cost,
        age_days,
        fund_id,
        comment,
        is_company,
        parentTx,
        childTx,
    } = transaction

    const {
        code,
        currentRate,
    } = asset

    const [inputDate, setInputDate] = useState(date)
    const [inputCost, setInputCost] = useState(Math.abs(cost))
    const [inputAmount, setInputAmount] = useState(Math.abs(amount))
    const [inputComment, setInputComment] = useState(comment)
    const [inputIsCompany, setInputIsCompany] = useState(is_company)

    useChillEffect(() => {
        setInputDate(date)
        setInputCost(Math.abs(cost))
        setInputAmount(Math.abs(amount))
        setInputComment(comment)
        setInputIsCompany(is_company)
    }, [id])

    const {
        verbClassName,
        verb,
        isDividend,
        isBurn,
        isSale,
    } = getTransactionParams(transaction)

    const isDateInputValid = !!inputDate
    const isAmountInputValid = !!inputAmount || isDividend
    const isCostInputValid = !!inputCost || isBurn
    const isFormChanged = inputDate !== date
        || inputCost !== Math.abs(cost)
        || inputAmount !== Math.abs(amount)
        || inputComment !== comment
        || inputIsCompany !== is_company

    const isUpdateAble = isFormChanged
        && isDateInputValid
        && isAmountInputValid
        && isCostInputValid

    const currentValue = amount * currentRate
    const valueDiff = currentValue - cost

    const changePct = Math.abs(valueDiff) / cost

    const updateData = {
        transactionId,
        date: inputDate,
        cost: inputCost * (cost < 0 ? -1 : 1),
        amount: inputAmount * (amount < 0 ? -1 : 1),
        comment: inputComment,
        isCompany: inputIsCompany,
    }

    const updateTransaction = async () => {
        await patchTransactionApi(transactionId, updateData)
        navigateTo(APP_ROUTES.transactions)
        setup(dispatch, true).then()
    }

    const deleteTransaction = async () => {
        if (window.confirm('Are you sure you want to delete this transaction?')) {
            await deleteTransactionApi(transactionId)
            navigateTo(APP_ROUTES.transactions)
            setup(dispatch, true).then()
        }
    }

    const textAreaHeight = Math.max(2 + 1.5 * inputComment?.split(/\r\n|\r|\n/).length, 10)

    return (
        <div className='transaction-page'>
            <h2>
                <InternalLink to={getRoute(APP_ROUTES.transactions, {filterId: fund_id})}>{asset_name}</InternalLink>
                <ChevronRightIcon/> <span className='tx-id'>tx {transactionId}</span>
            </h2>

            <div className='kpis'>
                <KPI header='Type' className={`type ${verbClassName}`}>
                    {verb}
                </KPI>
                <KPI header='Return'>
                    {isBurn ?
                        <GasIcon className='direction-icon is-gas'/>
                        :
                        <DirectionIcon value={valueDiff} isGolden={isDividend}/>
                    }
                    <Counter
                        amount={valueDiff}
                        absolute={true}
                    />
                    {!isDividend && !isBurn &&
                    <Counter
                        amount={changePct * 100}
                        suffix='%'
                        className={`secondary ${getDirectionColorClassName(valueDiff)}`}
                        absolute={true}
                    />
                    }
                </KPI>
                <KPI header='Age' >
                    <Counter
                        amount={age_days}
                        suffix={age_days === 1 ? ' day' : ' days'}
                    />
                </KPI>
                {!isDividend && !isBurn &&
                <KPI header='rate' className='hide-portrait'>
                    <Counter
                        amount={cost / amount}
                        suffix=''
                        decimals={DECIMAL_FORMAT.FLEX_DEC}
                    />
                </KPI>
                }
            </div>

            {parentTx &&
            <ConnectedTransaction
                {...parentTx}
            />
            }
            {childTx &&
            <ConnectedTransaction
                {...childTx}
            />
            }

            <div className='form'>
                <div className='inputs'>
                    <Input header='date'>
                        <input
                            type='date'
                            value={inputDate}
                            onChange={e => setInputDate(e.target.value)}
                        />
                    </Input>
                    <Input header={isSale || isDividend ? 'proceeds' : 'cost'}>
                        <input
                            type='number'
                            value={inputCost}
                            onChange={e => setInputCost(e.target.value)}
                            disabled={isBurn}
                        />
                        <div className='denominator'>
                            kr
                        </div>
                    </Input>
                    <Input header='quantity'>
                        <input
                            type='number'
                            value={inputAmount}
                            onChange={e => setInputAmount(e.target.value)}
                            disabled={isDividend}
                        />
                        <div className='denominator'>
                            {code}
                        </div>
                    </Input>
                </div>
                <textarea
                    value={inputComment}
                    onChange={e => setInputComment(e.target.value)}
                    style={{"--height": `${textAreaHeight}rem`}}
                />
            </div>
            <div className='footer'>
                <Toggle
                    isChecked={inputIsCompany}
                    onChange={value => setInputIsCompany(value)}
                >
                    Company transaction
                </Toggle>
                <div className='buttons'>
                    <Button
                        isRed={true}
                        onClick={deleteTransaction}
                    >
                        Delete
                    </Button>
                    <Button
                        disabled={!isUpdateAble}
                        onClick={updateTransaction}
                    >
                        Update
                    </Button>
                </div>
            </div>
        </div>
    )
}

const Input = ({header, children}) => {
    return (
        <div className='input-container'>
            <h3>
                {header}
            </h3>
            <div className='content'>
                {children}
            </div>
        </div>
    )
}

const KPI = ({header, className = '', children}) => {
    const hasHeader = !!header
    return (
        <div className={`kpi ${className} ${!hasHeader ? 'large-content' : ''}`}>
            {hasHeader &&
            <h3>
                {header}
            </h3>
            }
            <div className='kpi-value'>
                {children}
            </div>
        </div>
    )
}