import {getIsGiljarhus} from "../../../ducks/user-duck";
import {useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {APP_ROUTES} from "../../../App";

export default () => {
    const isGiljarhus = useSelector(getIsGiljarhus)

    return isGiljarhus && (
        <div>
            <Redirect to={APP_ROUTES.dawg} />
        </div>
    )
}