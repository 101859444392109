import './Chart.scss'
import {Chart} from "react-chartjs-2";
import {useRef, useState} from "react";
import ChartDuration from "./ChartDuration.jsx";
import ChartFocus from "./ChartFocus.jsx";
import {useChartData} from "./_useChartData.js";
import {useChillEffect} from "../../../util/mounting-hooks.js";
import {isMobile} from "../../../util/misc.js";
import {useSelector} from "react-redux";
import {CHART_PALETTE} from "./_chart-palette.js";
import {getIsAthGolden, getIsZeroBaseValue, getShowLatestValue} from "../../../ducks/chart-duck.js";
import {LoaderWrapperCircle} from "../../util/loaders/LoaderWrapper.jsx";
import {getOptions} from "./_options.js";
import {scrollToTop, useNavigation} from "../../util/navigation/navigation.js";
import {APP_ROUTES} from "../../../App.jsx";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon.js";
import {getCurrentAssetId, getDateFilter} from "../../../ducks/assets-duck.js";
import {useAnnotations} from "../../../api/use-annotations-api.js";

const durationToLineThickness = daysDuration => {
    if (daysDuration < 33) {
        return 5
    }
    if (daysDuration < 364) {
        return 4
    }
    if (daysDuration < 364 * 2) {
        return 3
    }
    return 2
}


export default () => {

    const dateFilter = useSelector(getDateFilter)
    const isLatestLineVisible = useSelector(getShowLatestValue)
    const isZeroBaseValue = useSelector(getIsZeroBaseValue)
    const isAthGolden = useSelector(getIsAthGolden)
    const assetId = useSelector(getCurrentAssetId)
    const {navigateTo} = useNavigation(scrollToTop)
    const {annotations: annotationsRaw} = useAnnotations()

    const chartRef = useRef()
    const {
        labels,
        values,
        isATH,
        isLatestMovePositive,
        onMouseMove,
        onMouseOver,
        onMouseOut,
        isHistoryFullyLoaded,
        isHovering,
    } = useChartData()

    const [data, setData] = useState({labels: [], datasets: []})
    useChillEffect(() => {

        const chartArea = chartRef.current?.chartArea
        const ctx = document.createElement('canvas')?.getContext("2d")

        let palette = isLatestMovePositive ? CHART_PALETTE.POSITIVE : CHART_PALETTE.NEGATIVE
        if (isATH && isAthGolden) {
            palette = CHART_PALETTE.GOLDEN
        }

        const {
            lineColor1,
            lineColor2,
            fillColor1,
            fillColor2,
            dashColor1,
            dashColor2,
        } = palette

        const lineGradient = ctx.createLinearGradient(chartArea?.left || 0, 0, chartArea?.right || 0, 0);
        lineGradient.addColorStop(1, lineColor1);
        lineGradient.addColorStop(0, lineColor2);

        const annotationGradient = ctx.createLinearGradient(chartArea?.top || 0, 0, chartArea?.bottom || 500, 0);
        annotationGradient.addColorStop(1, 'rgba(255,255,255,0)');
        annotationGradient.addColorStop(.921, 'rgba(255,255,255,0)');
        annotationGradient.addColorStop(.92, lineColor1);
        annotationGradient.addColorStop(0, fillColor2);

        const backgroundGradient = ctx.createLinearGradient(0, chartArea?.bottom || 0, 0, chartArea?.top || 500);
        backgroundGradient.addColorStop(1, fillColor1);
        backgroundGradient.addColorStop(0, fillColor2);

        const latestValueGradient = ctx.createLinearGradient(chartArea?.left || 0, 0, chartArea?.right || 0, 0);
        latestValueGradient.addColorStop(1, dashColor1);
        latestValueGradient.addColorStop(.1, dashColor2);

        const lineThickness = durationToLineThickness(values.length)

        setData({
            labels: labels,
            datasets: [
                {
                    label: 'Values',
                    data: values,
                    borderColor: lineGradient,
                    backgroundColor: backgroundGradient,
                    pointRadius: 0,
                    borderWidth: isMobile() ? lineThickness / 4 * 3 : lineThickness,
                    pointHoverRadius: 8,
                    pointHoverBorderWidth: 2,
                    pointHoverBorderColor: 'white',
                    pointHoverBackgroundColor: backgroundGradient,
                    lineTension: 0.15,
                    fill: true,
                },
                isLatestLineVisible && {
                    label: 'Latest',
                    data: values.map(() => values[0]),
                    borderColor: latestValueGradient,
                    backgroundColor: 'transparent',
                    borderDash: [10, 5],
                    pointRadius: 0,
                    pointHoverRadius: 6,
                    pointHoverBorderWidth: 1.5,
                    fill: false,
                },
            ].filter(dataset => !!dataset),
            palette: {
                lineGradient,
                backgroundGradient,
                annotationGradient,
            }
        })

        !isHovering && onMouseOut()

    }, [values.toString(), isLatestLineVisible, isAthGolden, isZeroBaseValue])

    const {
        palette = {},
        // eslint-disable-next-line
        labels: [],
    } = data


    const options = getOptions({
        onMouseLeave: onMouseOut,
        onMouseEnter: onMouseOver,
        onMouseMove,
        onClick: () => navigateTo(APP_ROUTES.applyHoverDateFilter),
        isZeroBaseValue,
        showDates: false,
        chart: chartRef,
        palette,
        labels,
        dateFilter,
        assetId,
        annotationsRaw,
    })

    return (
        <div className='chart'>
            <div className='chat-options'>
                <ChartFocus/>
                <ChartDuration/>
            </div>
            <LoaderWrapperCircle
                isLoading={!isHistoryFullyLoaded}
                isPositive={isLatestMovePositive}
            >
                {data.datasets[0]?.data.length <= 1 ?
                    <div className='no-data'>
                        <canvas/>
                        <div className={'no-data-desc'}>
                            <AlertCircleOutlineIcon/>
                            <div className='text'>
                                no data
                            </div>
                        </div>
                    </div>
                    :
                    <Chart
                        className={`no-select ${values.length < 1 ? 'transparent' : ''}`}
                        type='line'
                        data={data}
                        options={options}
                        ref={chartRef}
                    />
                }
            </LoaderWrapperCircle>
        </div>
    )
}

